import { useNavigation, useRoute } from "@react-navigation/native";
import React, { useEffect } from "react";
import { Button, Image } from "react-native-elements";

import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as Clipboard from "expo-clipboard";

import { ActionColor } from "../../../utils/colors";
import { formatCurrency } from "../../../utils/formatNumber";

import { PIX_IMAGE } from "../../../image";

import {
  AppInfo,
  BasketInfo,
  Container,
  InfoContent,
  Message,
  PixImage,
  ProductInfo,
  ProductItem,
  ProductList,
  ProductTotal,
  ProductsTitle,
  PurchaseTotal,
  Title,
} from "./styles";

import api from "../../../services/api";

const CardPayment: React.FC = () => {
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  const navigation = useNavigation();
  const { total, items, paymentId, machine } = route.params;
  const TEN_MINUTES = 60 * 1010 * 10;

  function sleep(milliSeconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliSeconds));
  }

  async function getPixPayment() {
    try {
      const response = await api.get(`/payment/${paymentId}`);
      return response;
    } catch (err) {
      navigation.navigate("PixError", { items, total });
    }
  }

  useEffect(() => {
    let stopCount = false;
    const timeout = setTimeout(async () => {
      stopCount = true;
      const response = await getPixPayment();
      const { status } = response?.data.payment;
      if (status == "paid") {
        clearTimeout(timeout);
        navigation.navigate("PaymentConfirmed", { items, total });
      } else {
        navigation.navigate("PixError", { items, total, machine });
      }
    }, TEN_MINUTES);

    (async () => {
      while (!stopCount) {
        await sleep(1000);
        const response = await getPixPayment();
        const { status } = response?.data.payment;
        if (status == "paid") {
          clearTimeout(timeout);
          navigation.navigate("PaymentConfirmed", { items, total });
          break;
        }
      }
    })();

    return () => {};
  }, []);

  return (
    <Container>
      <InfoContent>
        <AppInfo>
          <PixImage source={PIX_IMAGE} />
        </AppInfo>

        <Title>Pedido aguardando pagamento</Title>
        <Message>Insira, aproxime ou deslize o cartão</Message>
      </InfoContent>

      <BasketInfo>
        <ProductsTitle>Resumo da compra</ProductsTitle>
        <ProductList>
          {items.map(({ id, name, quantity, price }) => (
            <ProductItem key={id}>
              <ProductInfo>
                {name} x{quantity}
              </ProductInfo>
              <ProductTotal>
                {" "}
                {formatCurrency((quantity * price) / 100)}
              </ProductTotal>
            </ProductItem>
          ))}
        </ProductList>
        <PurchaseTotal>Total: {formatCurrency(total / 100)}</PurchaseTotal>
      </BasketInfo>
    </Container>
  );
};

export default CardPayment;

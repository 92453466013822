import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { RefreshControl, Text, View } from "react-native";
import { Button } from "react-native-elements";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import * as Yup from "yup";
import Input from "../../components/Input";
import Loading from "../../components/Loading";
import MachineSelectedView from "../../components/MachineSelectedView";
import MyModal from "../../components/MyModal";
import ProductItem from "../../components/ProductItem";
import Warning from "../../components/Warning";
import { useAuth } from "../../contexts/auth";
import { AlertOS } from "../../hooks/AlertOS";
import { NO_ITENS } from "../../image";
import { MachineIdentification } from "../../models/machine";
import { HomeRoutesStackTypes } from "../../routes/home.routes";
import api from "../../services/api";
import { colorGray, colorRed, InfoColor } from "../../utils/colors";
import { dinamicPadding } from "../../utils/dinamicPaddingInput";
import { formatCurrency } from "../../utils/formatNumber";
import {
  Container,
  InfoPurchase,
  ProductContainer,
  ProductList,
  ProductsAvaliable,
  PurchaseTotal,
} from "./styles";
import { Feather } from "@expo/vector-icons";

interface MachineBasketProps {
  machineId: string;
  machine: MachineIdentification;
}

const CodeFormValidationSchema = Yup.object().shape({
  code: Yup.string().required("Insira o código de verificação"),
});

const MachineBasket: React.FC = () => {
  const navigation = useNavigation<HomeRoutesStackTypes>();
  const route =
    useRoute<RouteProp<Record<string, MachineBasketProps>, string>>();
  const { machineId } = route.params;
  const [machine, setMachine] = useState<MachineIdentification>(
    route.params.machine
  );
  const [loading, setLoading] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [cartProducts, setCartProducts] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [total, setTotal] = useState(0);
  const { user } = useAuth();

  async function getMachine() {
    try {
      setLoading(true);
      const response = await api.get(`/machine/${machineId}`);
      const { machines } = response.data;
      let productsList = machines?.products.map((item) => {
        const { id, image, name, price } = item.product;
        return { id, image, name, price, maxQuantity: item.quantity };
      });
      updateTotalItems(productsList);
      setProductsList(productsList);
      setMachine(machines);
      setLoading(false);
    } catch (err) {
      console.log("ERROR: ", err);
      setLoading(false);
    }
  }

  async function saveCartItems(data) {
    try {
      setLoading(true);
      const params = {
        machine_id: machine.id,
        items: data,
      };
      api.post("/cart", { ...params });
      setLoading(false);
    } catch (err) {
      console.log("ERROR: ", err);
      setLoading(false);
    }
  }

  function handlePayment() {
    if (!total) {
      AlertOS({
        title: "Selecione um produto",
        message:
          "Não existem produtos no carrinho. Selecione um produto para prosseguir",
        toastType: "info",
      });
      return;
    }
    if (user.isTotemAccount) {
      navigation.navigate("PaymentTotem", {
        machine,
        total,
        items: [...cartProducts],
      });
    } else {
      navigation.navigate("Payment", {
        machine,
        total,
        items: [...cartProducts],
      });
    }
  }

  function updateTotalItems(items) {
    const total = items.reduce((acc, cur) => {
      acc += cur.price * cur.quantity;
      return acc;
    }, 0);
    setTotal(total);
  }

  useEffect(() => {
    updateTotalItems(cartProducts);
    saveCartItems(cartProducts);
  }, [cartProducts]);

  useEffect(() => {
    getMachine();
    // let productsList = machine?.products.map((item) => {
    //   const { id, image, name, price } = item.product;
    //   return { id, image, name, price, maxQuantity: item.quantity };
    // });
    updateTotalItems(productsList);
    setProductsList(productsList);
  }, []);

  function renderItem({ item }) {
    return (
      <ProductItem
        {...item}
        cartItems={cartProducts}
        updateCartItems={setCartProducts}
      />
    );
  }

  return loading ? (
    <Loading text="Carregando..." />
  ) : (
    <Container isTotemAccount={user.isTotemAccount}>
      <MachineSelectedView machine={machine} />
      {machine.products.length == 0 && !refreshing && (
        <Warning
          imageSource={NO_ITENS}
          title="Não há produtos nesta máquina"
        ></Warning>
      )}
      {refreshing ? (
        <Loading text={"Buscando produtos..."} />
      ) : (
        <ProductContainer
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={getMachine}
              enabled={true}
            />
          }
        >
          <ProductList
            numColumns={2}
            data={productsList}
            keyExtractor={(item) => item.id}
            renderItem={renderItem}
          />
        </ProductContainer>
      )}
      <InfoPurchase>
        <ProductsAvaliable>Itens a venda</ProductsAvaliable>
        <PurchaseTotal>Total: {formatCurrency(total / 100)}</PurchaseTotal>
      </InfoPurchase>
      <Button
        title="Ir para o carrinho"
        loading={loading}
        onPress={handlePayment}
      />
    </Container>
  );
};

export default MachineBasket;

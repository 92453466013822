import { MaterialIcons } from "@expo/vector-icons";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import axios from "axios";
import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { Button, Input } from "react-native-elements";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { MaskedTextInput } from "react-native-mask-text";
import * as Yup from "yup";
import MachineSelectedView from "../../components/MachineSelectedView";
import MyModal from "../../components/MyModal";
import { useAuth } from "../../contexts/auth";
import { AlertOS } from "../../hooks/AlertOS";
import { PIX, creditcardsimg } from "../../image";
import { MachineIdentification } from "../../models/machine";
import { Item } from "../../models/product";
import api from "../../services/api";
import { ActionColor, colorRed } from "../../utils/colors";
import { dinamicPadding } from "../../utils/dinamicPaddingInput";
import { formatCurrency } from "../../utils/formatNumber";
import { isValidCPF, isValidPhoneNumber } from "../../utils/valid";
import {
  BasketInfo,
  CardImage,
  CenteredView,
  Container,
  ContainerInput,
  ContainerPayment,
  FormContainer,
  InputLabel,
  InputTextError,
  ItemImage,
  ItemImagePix,
  ItemQuantify,
  ItemTitle,
  PaymentCard,
  PaymentCardText,
  PaymentTitle,
  Price,
  ProductItem,
  ProductList,
  ProductsTitle,
  PurchaseTotal,
  WrapperInput,
} from "./styles";
import Loading from "../../components/Loading";

type RouteParamList = {
  machine: MachineIdentification;
  total: number;
  items: any;
};

interface FormValuePix {
  cpf?: string;
  phone?: string;
}

const PixFormValidationSchema = Yup.object().shape({
  cpf: Yup.string()
    .test("test-number", "Insira um CPF válido!", (value) => isValidCPF(value))
    .required("Insira o seu CPF"),
  phone: Yup.string()
    .test("test-number", "Insira um Número válido!", (value) =>
      isValidPhoneNumber(value)
    )
    .required("Insira seu número de telefone"),
});

const Payment: React.FC = () => {
  let updatedCart;
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  let { machine } = route.params;
  const navigation = useNavigation();
  const { user } = useAuth();
  const [paymentMethodSelected, setPaymentMethodSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMSG, setLoadingMSG] = useState("");
  const [showPixForm, setShowPixForm] = useState(false);
  const [items, setItems] = useState(
    JSON.parse(JSON.stringify(route.params.items))
  );
  const [total, setTotal] = useState(route.params.total);
  const inputPhoneRef = useRef<typeof Input>(null);
  const MSG_LOADING_PIX = "Gerando o Pix. Aguarde ...";
  const MSG_LOADING_PAYMENT = "Processando pagamento ...";

  async function saveCartItems(data) {
    try {
      setLoading(true);
      const params = {
        machine_id: machine.id,
        items: data,
      };
      api.post("/cart", { ...params });
      setLoading(false);
    } catch (err) {
      console.log("ERROR: ", err);
      setLoading(false);
    }
  }

  async function handlePayment(paymentType: string) {
    if (!total) return;
    setLoading(true);
    setPaymentMethodSelected(paymentType.toLowerCase());
    if (paymentType.toLowerCase() == "pix") {
      AlertOS({
        title: "Forma de pagamento",
        message: "Pix selecionado",
        toastType: "success",
      });
      setShowPixForm(true);
    } else if (paymentType.toLowerCase() == "credit") {
      AlertOS({
        title: "Forma de pagamento",
        message: "Crédito selecionado",
        toastType: "success",
      });
      await ceditCard();
    } else if (paymentType.toLowerCase() == "debit") {
      AlertOS({
        title: "Forma de pagamento",
        message: "Débito selecionado",
        toastType: "success",
      });
      await debitCard();
    } else {
      AlertOS({
        title: "Qual forma de pagamento?",
        message: "Selecione uma forma de pagamento para prosseguir",
        toastType: "info",
      });
    }
    setLoading(false);
  }
  async function debitCard() {
    navigation.navigate("DebitCard", {
      machine,
      items,
      total,
    });
  }

  async function ceditCard() {
    navigation.navigate("CreditCard", {
      machine,
      items,
      total,
    });
  }

  async function pixPayment(body: any) {
    const response = await api.post("/payment/pix", { ...body });
    const { payment } = response.data;
    const { payment_id, qr_code, qr_code_url } = payment;
    return {
      payment_id,
      qr_code,
      qr_code_url,
    };
  }

  async function createTotemOrder(body) {
    const response = await api.post("/order/totem", { ...body });
    const { payment } = response.data;
    return payment;
  }

  async function processTotemPayment({
    order_id,
    payment_id,
    payment_type,
    nsu,
    authorization,
    date,
    value,
    client_via,
  }) {
    let order_items = items.map((product) => ({
      product_id: product.id,
      quantity: product.quantity,
    }));
    const body = {
      order_id,
      machine_id: machine.id,
      payment_id,
      payment_type,
      nsu,
      authorization,
      date,
      value,
      order_items,
      client_via,
    };
    const response = await api.post("/totem/payment/confirm", { ...body });
    return response;
  }

  async function handlePixPayment({ cpf, phone }: FormValuePix) {
    try {
      if (!cpf && !phone) {
        AlertOS({
          title: "Pagamento PIX",
          message:
            "Para prosseguir com o pagamento Pix, você deve informar o CPF e Número de telefone",
          toastType: "info",
        });
        return;
      }
      setLoading(true);
      setShowPixForm(false);
      const machineId = machine.id;
      const productsList = items.map((item) => {
        return {
          product_id: item.id,
          unit_price: item.price,
          description: item.name,
          quantity: item.quantity,
        };
      });
      setLoadingMSG(MSG_LOADING_PIX);
      const userPhoneNumber = {
        country_code: "55",
        number: phone.slice(2),
        area_code: phone.slice(0, 2),
      };
      const { qr_code, payment_id, qr_code_url } = await pixPayment({
        machine_id: machineId,
        products_purchase: productsList,
        cpf,
        phone_number: userPhoneNumber,
        save: false,
      });
      setLoading(false);
      navigation.navigate("Pix", {
        items,
        total,
        qrCodeString: qr_code,
        qrCodeImage: qr_code_url,
        paymentId: payment_id,
        machine,
      });
    } catch (err: any) {
      setLoading(false);
      AlertOS({
        title: "Não foi possível realizar o pagamento",
        message: "Problemas na conexão. Tente novamente",
        toastType: "info",
      });
    }
  }

  function handleDeleteItem(item: Item) {
    const productId = item.id;
    updatedCart = items
      .map((cartItem: Item) => {
        if (cartItem.id === productId) {
          cartItem.quantity -= 1;
        }
        return cartItem;
      })
      .filter((cartItem: Item) => cartItem.quantity > 0);
    saveCartItems(updatedCart);
    setTotal(
      updatedCart.reduce((acc, cur) => {
        acc += cur.price * cur.quantity;
        return acc;
      }, 0)
    );
    setItems(updatedCart);
  }

  return (
    <Container>
      <MachineSelectedView machine={machine} />
      <BasketInfo>
        <ProductsTitle>Resumo da compra</ProductsTitle>
        <ProductList>
          {items.map((item: Item) => (
            <ProductItem key={item.id}>
              <ItemImage source={{ uri: item.image }} />
              <ItemTitle>{item.name}</ItemTitle>
              <ItemQuantify>
                <MaterialIcons
                  onPress={() => handleDeleteItem(item)}
                  name="delete"
                  color={colorRed}
                  size={24}
                />
                <Price>
                  {item.quantity}x {formatCurrency(item.price / 100)}
                </Price>
              </ItemQuantify>
            </ProductItem>
          ))}
        </ProductList>
        <PurchaseTotal>Total: {formatCurrency(total / 100)}</PurchaseTotal>
      </BasketInfo>

      <ContainerPayment style={{ zIndex: 10 }}>
        <PaymentTitle>Forma de pagamento</PaymentTitle>
        <PaymentTitle>Cartões</PaymentTitle>
        <PaymentCard
          onPress={() => handlePayment("debit")}
          selected={paymentMethodSelected.toLowerCase() == "debit"}
        >
          <CardImage source={creditcardsimg("master")} />
          <PaymentCardText>Pagamento Débito</PaymentCardText>
        </PaymentCard>

        <PaymentCard
          onPress={() => handlePayment("credit")}
          selected={paymentMethodSelected.toLowerCase() == "credit"}
        >
          <CardImage source={creditcardsimg("master")} />
          <PaymentCardText>Pagamento Crédito</PaymentCardText>
        </PaymentCard>
        <PaymentTitle>Outras</PaymentTitle>

        <PaymentCard
          onPress={() => {
            handlePayment("pix");
          }}
          selected={paymentMethodSelected.toLowerCase() == "pix"}
        >
          <ItemImagePix source={PIX} />
          <PaymentCardText>Pix</PaymentCardText>
        </PaymentCard>
      </ContainerPayment>

      <MyModal visible={showPixForm}>
        <CenteredView>
          <KeyboardAwareScrollView contentContainerStyle={{ flex: 1 }}>
            <Formik
              initialValues={{
                cpf: user.cpf,
                phone: user.phone?.substring(2),
              }}
              validationSchema={PixFormValidationSchema}
              validateOnChange={true}
              onSubmit={(values) => {
                handlePixPayment({ cpf: values.cpf, phone: values.phone });
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
              }) => (
                <FormContainer>
                  <PaymentTitle>
                    Informe seu CPF e Número de telefone para prosseguir com o
                    pagamento Via Pix
                  </PaymentTitle>

                  <WrapperInput>
                    <InputLabel>Telefone</InputLabel>
                    <ContainerInput>
                      <MaterialIcons
                        color={ActionColor}
                        name="phone"
                        size={24}
                        style={{
                          marginRight: 14,
                        }}
                      />
                      <MaskedTextInput
                        style={{
                          flex: 1,
                          fontSize: 18,
                          paddingVertical: 8,
                        }}
                        mask="(99) 99999-9999"
                        placeholder="(99) 99999-9999"
                        placeholderTextColor={"rgb(134, 147, 158)"}
                        keyboardType="phone-pad"
                        onChangeText={(text: string, rawText: string): void => {
                          handleChange("phone")(rawText);
                        }}
                        onBlur={handleBlur("phone")}
                        ref={inputPhoneRef}
                        maxLength={15}
                        value={values.phone}
                        returnKeyType="next"
                        autoCapitalize="none"
                        autoCorrect={false}
                        onSubmitEditing={() => handleSubmit()}
                        autoComplete={undefined}
                      />
                    </ContainerInput>
                    <InputTextError
                      style={{
                        paddingBottom: dinamicPadding(
                          errors.phone,
                          touched.phone
                        ),
                      }}
                    >
                      {errors.phone}
                    </InputTextError>
                  </WrapperInput>

                  <WrapperInput>
                    <InputLabel>Cpf</InputLabel>
                    <ContainerInput>
                      <MaterialIcons
                        color={ActionColor}
                        name="person"
                        size={24}
                        style={{
                          marginRight: 14,
                        }}
                      />
                      <MaskedTextInput
                        style={{
                          flex: 1,
                          fontSize: 18,
                          paddingVertical: 8,
                        }}
                        mask="999.999.999-99"
                        keyboardType="numeric"
                        placeholder="999.999.999-99"
                        placeholderTextColor={"rgb(134, 147, 158)"}
                        onChangeText={(text: string, rawText: string) => {
                          handleChange("cpf")(rawText);
                        }}
                        value={values.cpf}
                        maxLength={14}
                        autoCapitalize="none"
                        returnKeyType="send"
                        autoCorrect={false}
                        onSubmitEditing={() => handleSubmit()}
                        autoComplete={undefined}
                      />
                    </ContainerInput>
                    <InputTextError
                      style={{
                        paddingBottom: dinamicPadding(errors.cpf, touched.cpf),
                      }}
                    >
                      {errors.cpf}
                    </InputTextError>
                  </WrapperInput>

                  <Button
                    title="Concluído"
                    loading={loading}
                    onPress={() => handleSubmit()}
                    containerStyle={{ alignSelf: "stretch" }}
                  />
                  <Button
                    title="Fechar"
                    type="clear"
                    loading={loading}
                    onPress={() => setShowPixForm(false)}
                    containerStyle={{ alignSelf: "stretch" }}
                  />
                </FormContainer>
              )}
            </Formik>
          </KeyboardAwareScrollView>
        </CenteredView>
      </MyModal>

      {/* <Button
        title="Confirmar Pagamento"
        loading={loading}
        onPress={handlePayment}
      /> */}
    </Container>
  );
};

export default Payment;

import { useNavigation, useRoute } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { ActionColor } from "../../../utils/colors";
import { formatCurrency } from "../../../utils/formatNumber";
import { PIX_IMAGE } from "../../../image";
import {
  AppInfo,
  BasketInfo,
  Container,
  InfoContent,
  Message,
  PixImage,
  ProductInfo,
  ProductItem,
  ProductList,
  ProductTotal,
  ProductsTitle,
  PurchaseTotal,
  Title,
} from "./styles";
import api from "../../../services/api";
import { ActivityIndicator } from "react-native";

const DebitCard: React.FC = () => {
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  const navigation = useNavigation();
  const { total, items, machine } = route.params;
  const [loading, setLoading] = useState(true);
  const TEN_MINUTES = 60 * 1000 * 5;
  const MSG_LOADING_PAYMENT_TITLE =
    "Um momento, estamos processando seu pagamento...";
  const MSG_LOADING_PAYMENT = "Aguarde...";

  function sleep(milliSeconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliSeconds));
  }

  async function getPaymentStatus(paymentId: string) {
    try {
      const response = await api.get(`/payment/${paymentId}`);
      const { status } = response.data.payment;
      return status;
    } catch (error) {
      console.error("Failed to get payment status", error);
      throw error;
    }
  }

  async function deleteCartItems(params) {
    try {
      await api.delete("/cart", { ...params });
    } catch (error) {
      console.error("Failed to delete cart items", error);
      throw error;
    }
  }

  async function createTotemOrder(body) {
    setLoading(true);
    try {
      const response = await api.post("/order/totem", { ...body });
      const { payment } = response.data;
      setLoading(false);
      return payment;
    } catch (error) {
      console.error("Failed to create order", error);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function verifyPayment(paymentId: string) {
    let timeoutReached = false;
    const handlePaymentStatus = async () => {
      const status = await getPaymentStatus(paymentId);
      if (status.toLowerCase() === "paid") {
        await deleteCartItems({ machine_id: machine.id });
        navigation.navigate("PaymentConfirmed", { items, total });
        return true;
      }
      return false;
    };
    const paymentTimeout = new Promise((_, reject) => {
      setTimeout(() => {
        timeoutReached = true;
        reject(new Error("Payment verification timeout"));
      }, TEN_MINUTES);
    });
    const checkPayment = async () => {
      while (!timeoutReached) {
        const paymentConfirmed = await handlePaymentStatus();
        if (paymentConfirmed) {
          return;
        }
        await sleep(1000);
      }
    };
    try {
      await Promise.race([checkPayment(), paymentTimeout]);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function handleDebitCard() {
    try {
      const createOrderItems = () => {
        return items.map((product) => ({
          product_id: product.id,
          quantity: product.quantity,
        }));
      };
      const order_items = createOrderItems();
      const body = {
        machine_id: machine.id,
        order_items,
        payment_type: "debit_card",
      };
      const data = await createTotemOrder(body);
      const { payment_id } = data;
      await verifyPayment(payment_id);
    } catch (error) {
      navigation.navigate("CardError", { items, total });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handleDebitCard();
  }, []);

  return (
    <Container>
      <InfoContent>
        <AppInfo>{/* <PixImage source={PIX_IMAGE} /> */}</AppInfo>
        <Title>
          {loading ? MSG_LOADING_PAYMENT_TITLE : "Pedido aguardando pagamento"}
        </Title>
        <Message>
          {loading ? (
            <>
              <ActivityIndicator size="small" color={ActionColor} />{" "}
              {MSG_LOADING_PAYMENT}
            </>
          ) : (
            "Insira, aproxime ou deslize o cartão"
          )}
        </Message>
      </InfoContent>
      <BasketInfo>
        <ProductsTitle>Resumo da compra</ProductsTitle>
        <ProductList>
          {items.map(({ id, name, quantity, price }) => (
            <ProductItem key={id}>
              <ProductInfo>
                {name} x{quantity}
              </ProductInfo>
              <ProductTotal>
                {" "}
                {formatCurrency((quantity * price) / 100)}
              </ProductTotal>
            </ProductItem>
          ))}
        </ProductList>
        <PurchaseTotal>Total: {formatCurrency(total / 100)}</PurchaseTotal>
      </BasketInfo>
    </Container>
  );
};

export default DebitCard;
